<style>
  .input-readonly > div > .vs-inputx {
    background-color: #e6e6e6;
  }

  .bg-dark {
    background-color: #e6e6e6 !important;
  }
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Supplier Invoice</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Supplier"
          name="Supplier"
          v-model="vendor"
          readonly
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="PO Code"
          name="PO Code"
          v-model="poCode"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="GR Code"
          name="GR Code"
          v-model="gr_codes"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Warehouse"
          name="Warehouse"
          v-model="warehouseName"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          v-validate="'required'"
          class="w-full"
          label="Invoice Number"
          name="Invoice Number"
          v-model="table.inv_code"
          placeholder="Invoice Number"
        />
        <span
          class="text-danger text-sm"
          v-if="table.inv_code == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Invoice Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Invoice Date"
          v-model="table.inv_date"
          placeholder="Select Invoice Date"
          format="MMMM dd, yyyy"
        ></datepicker>
        <span
          class="text-danger text-sm"
          v-if="table.inv_date == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div v-if="createdDate != 'January 1, 0001'" class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Created Date"
          name="Created Date"
          v-model="createdDate"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Payment Term</label>
        <multiselect
          class="selectExample"
          v-model="table.payment_term"
          :options="optionPaymentTerm"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="id"
          label="name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.name }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="table.payment_term == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-2">
      <!-- <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Due Date"
          name="Due Date"
          v-model="due_date"
          readonly
        />
      </div> -->
      <div class="vx-col w-full">
        <label class="vs-input--label">Due Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Due Date"
          v-model="due_date"
          placeholder="Due date"
          format="MMMM dd, yyyy"
        ></datepicker>
        <span
          class="text-danger text-sm"
          v-if="due_date == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Bank Name</label>
        <multiselect
          class="selectExample"
          v-model="table.bank"
          :options="optionSupplierAccountName"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="account_number"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">
                {{ dt.option.bank_name }} - {{ dt.option.account_number }}
                {{ dt.option.account_name }}
              </span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">
                {{ dt.option.bank_name }} - {{ dt.option.account_number }}
                {{ dt.option.account_name }}
              </span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Tax Invoice Number"
          name="Tax Invoice Number"
          v-model="tr_number"
          @keypress="isNumberAndFormat($event)"
          placeholder="Tax Invoice Number"
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Tax Invoice Date</label>
        <datepicker
          class="w-full"
          :inline="false"
          name="Invoice Date"
          v-model="tr_date"
          placeholder="Select Tax Date"
          format="MMMM dd, yyyy"
        ></datepicker>
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Currency Code"
          name="Currency Code"
          v-model="table.currency_code"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2 ml-1" >
      <h5>Line Item</h5>
    </div>
    <vs-table stripe border description :sst="true" :data="table.data">
      <template slot="thead">
        <vs-th width="18%">Item</vs-th>
        <vs-th width="18%">Qty</vs-th>
        <vs-th>Total Price</vs-th>
        <vs-th>Discount</vs-th>
        <vs-th>Net</vs-th>
        <vs-th>Tax</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="tr"
          :key="indextr"
          v-for="(tr, indextr) in data"
          :class="data[indextr].class"
        >
          <vs-td :data="data[indextr].code">
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].item_name }}</span>
            </div>
            <div class="vx-row ml-0">
              <span class="w-5/5"
                >Received :
                {{
                  data[indextr].qty_received
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
                {{ data[indextr].item_unit }}</span
              >
            </div>
          </vs-td>
          <vs-td>
            <span><small>PO</small></span>
            <vx-input-group class="d-flex">
              <vs-input
                class="w-full input-readonly"
                v-model="data[indextr].qty_po"
                readonly
              />

              <template slot="append">
                <div class="append-text bg-dark w-full">
                  <span style="color: black; outline-color: black; width: max-content"><small>{{ data[indextr].item_unit }}</small></span>
                </div>
              </template>
            </vx-input-group>
            <span><small>Invoice</small></span>
            <vx-input-group class="">
              <vs-input
                v-validate="'required'"
                @keypress="isNumber($event)"
                @input="formatNumber(indextr, 'qty')"
                class="w-full"
                name="Qty Inv"
                @focus="$event.target.select()"
                v-model="data[indextr].quantity"
              />
              <span
                class="text-danger text-sm"
                v-if="data[indextr].quantity == null && status_submit"
                >This field is required</span
              >

              <template slot="append">
                <div class="append-text bg-dark">
                  <span style="color: black; outline-color: black; width: max-content"><small>{{ data[indextr].item_unit }}</small></span>
                </div>
              </template>
            </vx-input-group>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span><small>PO</small></span>
              <vs-input
                class="w-full input-readonly"
                v-model="data[indextr].total_price_po"
                readonly
              />
              <span><small>Invoice</small></span>
              <vs-input
                v-validate="'required'"
                @keypress="isNumber($event)"
                @input="formatNumber(indextr, 'price')"
                @focus="$event.target.select()"
                class="w-full"
                name="Price Inv"
                v-model="data[indextr].net"
              />
              <span
                class="text-danger text-sm"
                v-if="data[indextr].net == null && status_submit"
                >This field is required</span
              >
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span><small>PO</small></span>
              <vs-input
                class="w-full input-readonly"
                v-model="data[indextr].discount_po"
                readonly
              />
              <span><small>Invoice</small></span>
              <vs-input
                v-validate="'required'"
                @keypress="isNumber($event)"
                @input="formatNumber(indextr, 'discount')"
                @focus="$event.target.select()"
                class="w-full"
                name="Discount"
                v-model="data[indextr].discount"
              />
              <span
                class="text-danger text-sm"
                v-if="data[indextr].discount == null && status_submit"
                >This field is required</span
              >
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span><small>PO</small></span>
              <vs-input
                class="w-full input-readonly"
                v-model="data[indextr].net_po"
                readonly
              />
              <span><small>Invoice</small></span>
              <vs-input
                class="w-full input-readonly"
                name="Tax"
                v-model="data[indextr].net"
                readonly
              />
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span><small>PO</small></span>
              <vs-input
                class="w-full input-readonly"
                v-model="data[indextr].tax_po"
                readonly
              />
              <span><small>Invoice</small></span>
              <vs-input
                v-validate="'required'"
                class="w-full input-readonly"
                name="Tax"
                v-model="data[indextr].tax"
                readonly
              />
              <span
                class="text-danger text-sm"
                v-if="data[indextr].tax == null && status_submit"
                >This field is required</span
              >
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="vx-row mb-2 mt-5 ml-1">
      <h5>Charges</h5>
    </div>
    <vs-table stripe border description :sst="true" :data="table.data_charges">
      <template slot="thead">
        <vs-th>Charge Name</vs-th>
        <vs-th>Value</vs-th>
        <vs-th>Tax</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{
                data[indextr].charge_name.replace(/-/g, " ")
              }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <vs-input
                v-validate="'required'"
                @keypress="isNumber($event)"
                @input="formatNumber(indextr, 'value')"
                @focus="$event.target.select()"
                class="w-full"
                name="Value"
                v-model="data[indextr].value"
              />
              <span
                class="text-danger text-sm"
                v-if="data[indextr].value == null && status_submit"
                >This field is required</span
              >
            </div>
          </vs-td>
          <div style="display: none">
            <!-- <p>{{ (tax_charges[indextr] = data[indextr].tax) }}</p> -->
            <!-- <p>{{ (charges[indextr] = data[indextr].value) }}</p> -->
          </div>
          <vs-td>
            <div class="vx-row ml-0">
              <vs-input
                class="w-full input-readonly"
                name="Tax"
                v-model="data[indextr].tax"
                readonly
              />
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-divider style="width: 100%; margin-left: 0%"><h5>Summary</h5></vs-divider>
    <div class="vx-row w-4/4 mb-3 mt-6"> 
      <vs-input
        class="w-1/4 input-readonly"
        label="Item Value PO"
        name="Item Value PO"
        v-model="table.item_value_po"
        readonly
      />
      <vs-input
        class="w-1/4 input-readonly pl-1 pr-1"
        label="Charges Value PO"
        name="Charges Value PO"
        v-model="table.charges_value_po"
        readonly
      />
      <vs-input
        class="w-1/4 input-readonly pr-1"
        label="Tax Value PO"
        name="Tax Value PO"
        v-model="table.tax_value_po"
        readonly
      />
      <vs-input
        class="w-1/4 input-readonly"
        label="Total Invoice Value PO"
        name="Total Invoice Value PO"
        v-model="table.total_po_value"
        readonly
      />
    </div>

    <!-- received -->
    <div class="vx-row w-4/4 mb-3 mt-6">
      <vs-input
        class="w-1/4 input-readonly"
        label="Item Value Received"
        name="Item Value Received"
        v-model="table.item_value_received"
        readonly
      />
      <vs-input
        class="w-1/4 input-readonly pl-1 pr-1"
        label="Charges Value Received"
        name="Charges Value Received"
        v-model="table.charges_value_received"
        readonly
      />
      <vs-input
        class="w-1/4 input-readonly pr-1"
        label="Tax Value Received"
        name="Tax Value Received"
        v-model="table.tax_value_received"
        readonly
      />
      <vs-input
        class="w-1/4 input-readonly"
        label="Total Invoice Value Received"
        name="Total Invoice Value Received"
        v-model="table.total_received_value"
        readonly
      />
    </div>

    <!-- invoice -->
    <div class="vx-row w-4/4 mb-3 mt-6">
      <vs-input
        class="w-1/4 input-readonly"
        label="Item Value Inv"
        name="Item Value Inv"
        v-model="table.item_value"
        readonly
      />
      <vs-input
        class="w-1/4 input-readonly pl-1 pr-1"
        label="Charges Value Inv"
        name="Charges Value Inv"
        v-model="table.charges_value"
        readonly
      />
      <vs-input
        class="w-1/4 input-readonly pr-1"
        label="Tax Value Inv"
        name="Tax Value Inv"
        v-model="table.tax_value"
        readonly
      />
      <vs-input
        class="w-1/4 input-readonly"
        label="Total Invoice Value Inv"
        name="Total Invoice Value Inv"
        v-model="table.total_inv_value"
        readonly
      />
    </div>
    <div class="vx-row w-4/4 mb-3 mt-6">
      <vs-input
        class="w-1/4 input-readonly"
        label="Item Value Faktur"
        name="Item Value Faktur"
        v-model="table.item_value_faktur"
        readonly
      />
      <vs-input
        class="w-1/4 input-readonly pl-1 pr-1"
        label="Charges Value Faktur"
        name="Charges Value Faktur"
        v-model="table.charges_value_faktur"
        readonly
      />
      <vs-input
        class="w-1/4 input-readonly pr-1"
        label="Tax Value Faktur"
        name="Tax Value Faktur"
        v-model="table.tax_faktur"
        readonly
      />
      <vs-input
        class="w-1/4 input-readonly"
        label="Total Invoice Value Faktur"
        name="Total Invoice Value Faktur"
        v-model="table.total_value_faktur"
        readonly
      />
    </div>

    <vs-divider style="width: 100%; margin-left: 0%"></vs-divider>
    <div class="vx-row mb-6">
        <div class="vx-col w-full mb-6">
            <table width="100%" class="vs-table vs-table--tbody-table mb-6">
                <thead class="vs-table--thead">
                    <tr>
                        <th width="5%">Chart Of Account</th>
                        <th width="5%">Cost Center</th>
                        <th width="5%">Amount</th>
                        <th width="5%">Description</th>
                    </tr>
                </thead>
                <tbody>
                  <tr v-for="(invoiceCoa, index) in invoiceCoas" :key="index" style="padding-bottom: 5px">
                    <td class="td vs-table--td" style="padding: 5px">
                      <vs-input
                        class="input-readonly"
                        label=""
                        v-model="invoiceCoa.coa"
                        style="width:100%"
                        readonly
                      />
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                      <vs-input
                        class="input-readonly"
                        label=""
                        v-model="invoiceCoa.cc"
                        style="width:100%"
                        readonly
                      />
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                      <vs-input
                        class="input-readonly"
                        label=""
                        v-model="invoiceCoa.amount"
                        style="width:100%"
                        readonly
                      />
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                      <vs-input
                        class="input-readonly"
                        label=""
                        v-model="invoiceCoa.description"
                        style="width:100%"
                        readonly
                      />
                    </td>
                  </tr>
                </tbody>
            </table>
        </div>
    </div>

    <vs-divider style="width: 100%; margin-left: 2%"><b>Notes</b></vs-divider>
    <template v-for="note in notes">
      <b v-bind:key="note">{{note}}
        <br>
      </b>
    </template>
    <br>
    <vs-textarea
      v-model="new_notes"
    />
    <vs-divider style="width: 100%; margin-left: 2%">Attachment</vs-divider>
    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>File</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <div class="vx-col sm:w-4/5 w-full">
          <input
            id="fileInput"
            name="file"
            class="w-full inputx"
            type="file"
            ref="file"
            accept=".jpg, .jpeg, .png, .pdf"
            multiple
          />
        </div>
      </div>
    </div>
    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span></span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <div class="vx-col sm:w-4/5 w-full">
          <vs-button class="mr-3 mb-2" @click="handleAttachment"
            >Add Attachment</vs-button
          >
        </div>
      </div>
    </div>
    <vs-divider style="width: 100%; margin-left: 2%"
      >List Attachment</vs-divider
    >
    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <table class="vs-table vs-table--tbody-table">
        <template v-for="(tr, i) in table.file">
          <tr
            class="tr-values vs-table--tr tr-table-state-null selected"
            v-bind:key="tr.name"
          >
            <td class="td vs-table--td">{{ tr.doc_type }}</td>
            <td class="td vs-table--td">{{ tr.name }}</td>
            <td class="td vs-table--td">
              <vx-tooltip text="Show" v-if="tr.path != ''">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  @click.stop="previewAttachment(tr.path)"
                  icon="icon-eye"
                />
              </vx-tooltip>
            </td>
            <td class="td vs-table--td">
              <vx-tooltip text="Download" v-if="tr.path != ''">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  @click.stop="downloadFileAwsS3(tr.path)"
                  icon="icon-download"
                />
              </vx-tooltip>
            </td>
            <td class="td vs-table--td">
              <template>
                <vx-tooltip text="Delete">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    @click.stop="handleDeleteAttachment(i,tr.id)"
                    icon="icon-trash"
                  />
                </vx-tooltip>
              </template>
            </td>
          </tr>
        </template>
      </table>
    </div>
    <vs-divider style="width: 100%; margin-left: 2%"></vs-divider>

    <div class="vx-row mb-6 mt-6">
      <div class="vx-col w-full" style="margin-bottom: 150px">
        <vs-button
          v-if="invCode === '0'"
          class="mr-3 mb-2"
          @click="handleCreate"
          >Create</vs-button
        >
        <vs-button v-else class="mr-3 mb-2" @click="handleUpdate"
          >Update</vs-button
        >
      </div>
    </div>

    <vs-popup title="Preview" :active.sync="showAttachment" :fullscreen="true">
      <iframe
        v-if="showAttachment"
        :src="attachmentPath"
        style="width: 100%; height: 80dvh"
      />
    </vs-popup>

    <!-- <div>
      <vs-prompt
        color="primary"
        title="Information"
        @cancel="val = ''"
        @accept="doContinue"
        @close="closeContinue"
        :is-valid="true"
        :active.sync="activePromptContinue"
      >
        <div class="con-exemple-prompt">
          Only notes and attachment will be update !!!
        </div>
      </vs-prompt>
    </div> -->
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  components: {
    Datepicker,
  },
  props: {
    piId: {
      type: Number,
    },
    vendor: {
      type: String,
    },
    poCode: {
      type: String,
    },
    invCode: {
      type: String,
    },
    warehouseCode: {
      type: String,
    },
    warehouseName: {
      type: String,
    },
    createdDate: {
      type: String,
    },
    dueDate: {
      type: String,
    },
    invType: {
      type: Number,
    },
  },
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        // activePromptContinue: false,
        showAttachment: false,
        attachmentPath: "",
        optionPaymentTerm: [],
        optionBankName: [],
        optionSupplierAccountName: [],
        search: "",
        offset: 0,
        limit: 10,
        status_submit: false,
        table: {
          inv_code: null,
          inv_date: null,
          payment_term: null,
          bank: {},
          bank_name: null,
          account_name: null,
          account_number: null,
          total_inv_value: 0,
          total_value_faktur: 0,
          item_value: 0,
          item_value_faktur: 0,
          charges_value: 0,
          charges_value_faktur: 0,
          discount_value_faktur: 0,
          tax_value: 0,
          tax_faktur: 0,
          data: [],
          data_charges: [],
          file: [],
        },
        // tax_item: [],
        // tax_charges: [],
        notes: "",
        gr_codes: "",
        new_notes: "",
        charges: [],
        net: [],
        total: [],
        taxx: [0, 0],
        file: {},
        total_discount: 0,
        type_submit: "",
        tr_number: null,
        tr_date: null,
        due_date: null,
        invoice_baseline: null,
        received_date: null,
        dn_date: null,
        territory_id: 0,
        disable: false,
        invoiceCoas: [{coa: "", cc: "", amount:""}],
      };
    },
    number_format(text){
      return text.toFixed(2).toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    isNumberAndFormat: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (charCode != 8 && this.tr_number != null) {
        if (this.tr_number.length == 3) {
          this.tr_number = this.tr_number + ".";
        } else if (this.tr_number.length == 7) {
          this.tr_number = this.tr_number + "-";
        } else if (this.tr_number.length == 10) {
          this.tr_number = this.tr_number + ".";
        } else if (this.tr_number.length > 18) {
          evt.preventDefault();
        }
      }
    },
    getOptionAccountBank(supplier_id, bankIDSelected = 0) {
      let warehouse_id = this.warehouse == null ? 0 : this.warehouse.ID
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/supplier-account/" + supplier_id, {
          params: {
            warehouse_id: warehouse_id,
            territory_id: this.$userLogin.territory_id,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          let option = []
          resp.data.account.map((v) => {
            const newOption = {
              bank_name : v.BankName,
              bank_id: v.BankID,
              account_name: v.AccountName,
              account_number: v.AccountNumber
            }
            this.optionSupplierAccountName.push(newOption)
            if (bankIDSelected != 0 && bankIDSelected != "") { 
              if (v.BankID == bankIDSelected) {
                // this.table.bank = {
                //   bank_name : v.BankName,
                //   bank_id: v.BankID,
                //   account_name: v.AccountName,
                //   account_number: v.AccountNumber
                // }
              }
            } else {
              if (v.IsDefault == 1) {
                this.table.bank = {
                  bank_name : v.BankName,
                  bank_id: v.BankID,
                  account_name: v.AccountName,
                  account_number: v.AccountNumber
                }
              }
            }
            
          })
          this.$vs.loading.close();
        });
    },
    getOptionBankName() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/bank", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionBankName = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Bank Name option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionPaymentTerm() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/payment-term", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionPaymentTerm = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Payment Term option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    handleDeleteAttachment(index,id) {
      this.table.file = this.table.file.filter((item, i) => i != index);
      this.deleteFile(id);
      // console.log(this.table.file);
    },
    handleAttachment() {
      if (this.$refs.file.files[0]) {
        let a = this.$refs.file.files
        for (let i = 0; i < a.length; i++) {
          console.log(a[i])
          if(a[i].size <= 5000000){ // 5mb
            this.file = a[i];
            this.uploadData();
          } else {
            this.$vs.notify({
              title: "Error",
              text: a[i].name + " is greater than 5MB",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        }
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleUpdate() {
      this.status_submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.type_submit = "update";
          this.$vs.loading();
          this.postData();
          console.log(this.paramData());
        }
      });
    },
    handleCreate() {
      this.status_submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.type_submit = "create";
          this.$vs.loading();
          this.postData();
          console.log(this.paramData());
        }
      });
    },
    formatNumber(index, status) {
      // console.log(status)
      if (status === "qty") {
        if (this.table.data[index].quantity.replace(/,/g, "").length < 16) {
          var str = this.table.data[index].quantity.toString().split(".");
          if (str.length > 2) {
            this.table.data[index].quantity = this.table.data[index].quantity
              .toString()
              .replace(/\.+$/, "");
          }

          if (str.length == 2) {
            if (str[1].length > 3) {
              this.table.data[index].quantity = this.table.data[index].quantity
                .toString()
                .slice(0, -1);
            }
          }

          this.table.data[index].quantity = (
            this.table.data[index].quantity.replace(/,/g, "")
          )
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          this.table.data[index].quantity = this.table.data[index].quantity
            .toString()
            .slice(0, -1);
        }
      } else if (status === "price") {
        if (this.table.data[index].net.replace(/,/g, "").length < 16) {
          var str1 = this.table.data[index].net.toString().split(".");
          if (str1.length > 2) {
            this.table.data[index].net = this.table.data[index].net
              .toString()
              .replace(/\.+$/, "");
          }

          if (str1.length == 2) {
            if (str1[1].length > 3) {
              this.table.data[index].net = this.table.data[index].net
                .toString()
                .slice(0, -1);
            }
          }

          this.table.data[index].net = (
            this.table.data[index].net.replace(/,/g, "")
          )
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          this.net[index] = this.table.data[index].net;
          this.cal();
        } else {
          this.table.data[index].net = this.table.data[index].net
            .toString()
            .slice(0, -1);
        }
      } else if (status === "value") {
        if (
          this.table.data_charges[index].value.replace(/,/g, "").length < 16
        ) {
          var str2 = this.table.data_charges[index].value.toString().split(".");
          if (str2.length > 2) {
            this.table.data_charges[index].value = this.table.data_charges[
              index
            ].value
              .toString()
              .replace(/\.+$/, "");
          }

          if (str2.length == 2) {
            if (str2[1].length > 3) {
              this.table.data_charges[index].value = this.table.data_charges[
                index
              ].value
                .toString()
                .slice(0, -1);
            }
          }

          this.table.data_charges[index].value = (
            this.table.data_charges[index].value.replace(/,/g, "")
          )
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          this.charges[index] = this.table.data_charges[index].value;

          this.cal();
        } else {
          this.table.data_charges[index].value = this.table.data_charges[
            index
          ].value
            .toString()
            .slice(0, -1);
        }
      } else if (status === "tax") {
        // if (this.table.data_charges[index].tax.replace(/,/g, "").length < 16) {
        //   var str3 = this.table.data_charges[index].tax.toString().split(".");
        //   if (str3.length > 2) {
        //     this.table.data_charges[index].tax = this.table.data_charges[
        //       index
        //     ].tax
        //       .toString()
        //       .replace(/\.+$/, "");
        //   }

        //   if (str3.length == 2) {
        //     if (str3[1].length > 3) {
        //       this.table.data_charges[index].tax = this.table.data_charges[
        //         index
        //       ].tax
        //         .toString()
        //         .slice(0, -1);
        //     }
        //   }

        //   this.table.data_charges[index].tax = Number(
        //     this.table.data_charges[index].tax.replace(/,/g, "")
        //   )
        //     .toString()
        //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        //   this.tax_charges[index] = this.table.data_charges[index].tax;

        //   this.cal();
        // } else {
        //   this.table.data_charges[index].tax = this.table.data_charges[
        //     index
        //   ].tax
        //     .toString()
        //     .slice(0, -1);
        // }
      } else if (status === "tax-item") {
        // if (this.table.data[index].tax.replace(/,/g, "").length < 16) {
        //   var str4 = this.table.data[index].tax.toString().split(".");
        //   if (str4.length > 2) {
        //     this.table.data[index].tax = this.table.data[index].tax
        //       .toString()
        //       .replace(/\.+$/, "");
        //   }

        //   if (str4.length == 2) {
        //     if (str4[1].length > 3) {
        //       this.table.data[index].tax = this.table.data[index].tax
        //         .toString()
        //         .slice(0, -1);
        //     }
        //   }

        //   this.table.data[index].tax = Number(
        //     this.table.data[index].tax.replace(/,/g, "")
        //   )
        //     .toString()
        //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        //   this.tax_item[index] = this.table.data[index].tax;
        //   this.cal();
        // } else {
        //   this.table.data[index].tax = this.table.data[index].tax
        //     .toString()
        //     .slice(0, -1);
        // }
      } else if (status === "discount") {
        if (this.table.data[index].discount.replace(/,/g, "").length < 16) {
          var str5 = this.table.data[index].discount.toString().split(".");
          if (str5.length > 2) {
            this.table.data[index].discount = this.table.data[index].discount
              .toString()
              .replace(/\.+$/, "");
          }

          if (str5.length == 2) {
            if (str5[1].length > 3) {
              this.table.data[index].discount = this.table.data[index].discount
                .toString()
                .slice(0, -1);
            }
          }

          this.table.data[index].discount = (
            this.table.data[index].discount.replace(/,/g, "")
          )
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          // this.discount[index] = this.table.data[index].discount;
          this.cal();
        } else {
          this.table.data[index].net = this.table.data[index].net
            .toString()
            .slice(0, -1);
        }
      }
    },
    cal() {
      console.log("calculate.....")
      let a = 0;
      let b = 0;
      let c = 0;
      let d = 0;
      let e = 0;

      // calculate tax item
      this.table.data.map((r, i) => {
        this.table.data[i].tax = parseFloat(parseInt(this.table.data[i].tax_rate) / 100) * (parseFloat(this.table.data[i].net.replace(/,/g, "") - parseFloat(this.table.data[i].discount.toString().replace(/,/g, ""))));
        this.table.data[i].subtotal = (parseFloat(this.table.data[i].net.replace(/,/g, "") - parseFloat(this.table.data[i].discount.toString().replace(/,/g, "")))).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        a += this.table.data[i].tax
        e += parseFloat(this.table.data[i].discount.toString().replace(/,/g, ""))
        d += parseFloat(this.table.data[i].net.toString().replace(/,/g, ""))
        this.table.data[i].tax = this.table.data[i].tax.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      });

      // calculate tax charge
      this.table.data_charges.map((r, i) => {
        this.table.data_charges[i].tax = (this.table.data_charges[i].tax_rate / 100) * parseInt(this.table.data_charges[i].value.replace(/,/g, ""));
        b += this.table.data_charges[i].tax
        c += parseFloat(this.table.data_charges[i].value.toString().replace(/,/g, ""))
        this.table.data_charges[i].tax = this.table.data_charges[i].tax.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      });

      // let a = 0;
      // this.tax_item.forEach((element) => {
      //   if (element !== "") {
      //     a += parseInt(element.toString().replace(/,/g, ""));
      //   }
      // });

      // let b = 0;
      // this.tax_charges.forEach((element) => {
      //   if (element !== "") {
      //     b += parseInt(element.toString().replace(/,/g, ""));
      //   }
      // });
      // this.charges.forEach((element) => {
      //   if (element !== "") {
      //     c += parseInt(element.toString().replace(/,/g, ""));
      //   }
      // });

      // this.net.forEach((element) => {
      //   if (element !== "") {
      //     d += parseInt(element.toString().replace(/,/g, ""));
      //   }
      // });

      this.$nextTick(() => {
        this.table.tax_value = (a + b).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.table.tax_value_ori = (a + b).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.table.charges_value = c.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.table.item_value = d.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.table.discount_value = e.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.table.total_inv_value = ((a + b) + (c + d - e)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    paramData() {
      return {
        pi_id: this.piId,
        inv_code: this.table.inv_code,
        inv_date: this.table.inv_date,
        due_invoice_period: this.table.payment_term.due_invoice_period,
        reference_purchase_code: this.poCode,
        payment_term_id: this.table.payment_term.id,
        payment_term_name: this.table.payment_term.name,
        bank_name: this.table.bank.bank_name,
        bank_id: this.table.bank.bank_id,
        account_name: this.table.bank.account_name,
        account_number: this.table.bank.account_number,
        total_inv_value: this.table.total_inv_value.toString(),
        item_value: this.table.item_value.toString(),
        charge_value: this.table.charges_value.toString(),
        tax_value: this.table.tax_value_ori.toString(),
        data_line: this.table.data,
        data_charge: this.table.data_charges,
        total_discount: this.table.discount_value,
        status_submit: this.type_submit,
        tr_number: this.tr_number,
        tr_date: this.tr_date == "" ? moment(String("0001-01-01T15:04:05Z07:00")) : this.tr_date,
        due_date: moment(this.due_date).add(7, 'h'),
        notes: this.new_notes,
      };
    },
    postData() {
      this.$http
        .post("/api/v1/pi/create-invoice", this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    paramUpload() {
      var form = new FormData()
      form.append('pi_id',this.piId)
      form.append('file', this.file)
      form.append("type", this.file.type);
      return form
    },
    uploadData() {
      this.$vs.loading()
      this.$http
        .post("/api/v1/pi/upload-file", this.paramUpload(), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.table.file.push({
            id: resp.data.attachment.ID,
            name: resp.data.attachment.NameFile,
            path: resp.data.attachment.PathFile,
          });
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    deleteFile(id) {
      this.$vs.loading()
      var form = new FormData()
      form.append('id',id)
      this.$http
        .post("/api/v1/pi/delete-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/vendor-invoice-line/" + this.piId)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            if(resp.data.inv_type == 1){
              this.createdDate = moment(String(new Date(resp.data.released_date))).format("YYYY-MM-DD");
            }
            this.invoice_baseline = resp.data.invoice_baseline;
            this.gr_codes = resp.data.gr_codes;
            this.dn_date = resp.data.dn_date;
            this.received_date = resp.data.received_date;
            this.territory_id = resp.data.territory_id;
            this.table.inv_date =
              this.invCode != 0 ? new Date(resp.data.inv_date) : null;
            if (resp.data.payment_term_id !== 0) {
              this.table.payment_term = {
                id: resp.data.payment_term_id,
                name: resp.data.payment_term_name,
                due_invoice_period: 0,
              };

              this.$nextTick(() => {
                this.due_date = this.dueDate;
              });
            }
            this.getOptionAccountBank(resp.data.supplier_id,resp.data.bank_id)
            if (resp.data.bank_id != 0 || resp.data.bank_id != "") {
              this.table.bank = {
                bank_name : resp.data.bank_name,
                bank_id: resp.data.bank_id,
                account_name: resp.data.account_name,
                account_number: resp.data.account_number,
              }
            }

            this.tr_date = moment(String(resp.data.tr_date)).format("YYYY-MM-DD") == "0001-01-01" ? "" : new Date(resp.data.tr_date);
            this.tr_number = resp.data.tr_number;
            this.notes = resp.data.supplier_invoice_notes.toString().split(";")
            this.table.account_name = resp.data.account_name;
            this.table.currency_code = resp.data.currency_code;
            this.table.account_number = resp.data.account_number;

            this.table.item_value_po = this.number_format(resp.data.item_value_po-resp.data.discount_value_po)
            this.table.charges_value_po = this.number_format(resp.data.charge_value_po)
            this.table.tax_value_po = this.number_format(resp.data.tax_value_po)
            this.table.discount_value_po = this.number_format(resp.data.discount_value_po)
            this.table.total_po_value = this.number_format(resp.data.total_po_value)

            this.table.item_value_received = this.number_format(resp.data.item_value_received-resp.data.discount_value_received)
            this.table.charges_value_received = this.number_format(resp.data.charge_value_received)
            this.table.tax_value_received = this.number_format(resp.data.tax_value_received)
            this.table.discount_value_received = this.number_format(resp.data.discount_value_received)
            this.table.total_received_value = this.number_format(resp.data.total_received_value)
            // console.log("charge faktur",resp.data.charges_value_faktur)
            this.table.item_value_faktur = this.number_format(resp.data.item_value_faktur)
            this.table.charges_value_faktur = this.number_format(resp.data.charge_value_faktur)
            this.table.tax_faktur = this.number_format(resp.data.tax_faktur)
            this.table.discount_value_faktur = this.number_format(resp.data.discount_value_faktur)
            this.table.total_value_faktur = this.number_format(resp.data.total_value_faktur)
            
            this.table.discount_value = resp.data.total_discount
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // this.table.account_name = resp.data.account_name;
            // this.table.account_number = resp.data.account_number;
            this.table.total_inv_value = resp.data.total_invoice_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.item_value = resp.data.item_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.charges_value = resp.data.charge_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.tax_value = resp.data.tax_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.tax_value_ori = resp.data.tax_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.po_code = resp.data.po_code;
            this.table.vendor = resp.data.vendor;
            // this.table.data = resp.data.data_line;
            // this.table.data_charges = resp.data.data_charge;

            let _this = this;
            resp.data.data_line.forEach(function (element, index) {
              _this.table.data.push({
                net: element.net_inv
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                tax: element.tax
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                quantity: element.quantity
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                item_name: element.item_name,
                item_unit: element.item_unit,
                id: element.id,
                amount_uom: element.amount_uom,
                qty_received: element.qty_received.toString(),
                tax_rate: element.tax_rate,
                discount: element.discount_inv
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                subtotal: "0.00",
                total_price_po: (parseFloat(element.price_po) * parseFloat(element.qty_po))
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                net_po: (parseFloat(element.price_po) * parseFloat(element.qty_po) - parseFloat(element.discount))
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                tax_po: ((parseFloat(element.price_po) * parseFloat(element.qty_po) - parseFloat(element.discount)) * (element.tax_rate/100))
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                discount_po: element.discount
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                qty_po: element.qty_po
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              });
              // _this.cal()
            });

            resp.data.data_charge.forEach(function (element, index) {
              _this.table.data_charges.push({
                value: element.value
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                tax: element.tax
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                id: element.id,
                charge_name: element.charge_name,
                tax_rate: element.tax_rate,
              });
              // _this.cal()
            });
            resp.data.data_attachment.map((r,i) => {
              _this.table.file.push({
                id: r.id,
                name: r.name,
                path: r.path,
                doc_type: r.doc_type,
              })
            })
            
            if(resp.data.data_coa.length > 0){
              _this.invoiceCoas = [];
              resp.data.data_coa.forEach(function (element, index) {
                _this.invoiceCoas.push({
                  coa: element.coa_code + " - " + element.coa_name,
                  cc: element.cc_code + " - " + element.cc_name,
                  amount: element.amount,
                  description: element.description,
                });
              });
            }
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    // doContinue(){
    //   this.activePromptContinue = false
    // }, 
    // closeContinue(){
    //   this.activePromptContinue = false
    // }
    async previewAttachment(path) {
      const url = await this.previewFileAwsS3(path);
      if (url) {
        this.attachmentPath = url;
        this.showAttachment = true;
      }
    },
  },
  mounted() {
    if (this.piId) {
      if (this.invType == 1) {
        console.log("invoice type ocr")
        this.disable = true
      }
      this.getData();
      this.table.inv_code = this.invCode != 0 ? this.invCode : null;
    }
    this.getOptionPaymentTerm();
    this.getOptionBankName();
  },
  computed: {},
  watch: {
    piId() {
      Object.assign(this.$data, this.initialState());
      this.getData();
      this.getOptionPaymentTerm();
      this.getOptionBankName();
      this.table.inv_code = this.invCode != 0 ? this.invCode : null;
    },
    "optionPaymentTerm": function () {
      if (this.optionPaymentTerm.length > 0 && this.table.payment_term != null) {
        let _this = this;
        var result = this.optionPaymentTerm.filter(function (o) {
          return o.id == _this.table.payment_term.id;
        });
        this.table.payment_term.due_invoice_period = result[0].due_invoice_period;
      }
    },
    "table.payment_term": function () {
      if (this.optionPaymentTerm.length > 0 && this.table.payment_term != null) {
        let _this = this;
        var result = this.optionPaymentTerm.filter(function (o) {
          return o.id == _this.table.payment_term.id;
        });
        this.table.payment_term.due_invoice_period = result[0].due_invoice_period;

        if (this.invoice_baseline == 'Invoice Date'){
          this.due_date = moment(String(this.table.inv_date)).add(result[0].due_invoice_period, 'days').format("DD/MM/YYYY");
        } else if (this.invoice_baseline == 'Invoice Create Date') {
          if(this.invCode === '0'){
            this.due_date = moment().add(result[0].due_invoice_period, 'days').format("DD/MM/YYYY");
          } else {
            this.due_date = moment(String(this.createdDate)).add(result[0].due_invoice_period, 'days').format("DD/MM/YYYY");
          }
        } else if (this.invoice_baseline == 'Delivery Note Date'){
          this.due_date = moment(String(this.dn_date)).add(this.table.payment_term.due_invoice_period, 'days').format("DD/MM/YYYY");
        } else if (this.invoice_baseline == 'GR Date'){
          this.due_date = moment(String(this.received_date)).add(this.table.payment_term.due_invoice_period, 'days').format("DD/MM/YYYY");
        }
      }
    },
    "table.inv_date": function () {
      if (this.table.payment_term != null && this.invoice_baseline == 'Invoice Date') {
        this.due_date = moment(String(this.table.inv_date)).add(this.table.payment_term.due_invoice_period, 'days').format("DD/MM/YYYY");
      } else if (this.invoice_baseline == 'Invoice Create Date') {
        if(this.invCode === '0'){
          this.due_date = moment().add(this.table.payment_term.due_invoice_period, 'days').format("DD/MM/YYYY");
        } else {
          this.due_date = moment(String(this.createdDate)).add(this.table.payment_term.due_invoice_period, 'days').format("DD/MM/YYYY");
        }
      } else if (this.invoice_baseline == 'Delivery Note Date'){
        this.due_date = moment(String(this.dn_date)).add(this.table.payment_term.due_invoice_period, 'days').format("DD/MM/YYYY");
      } else if (this.invoice_baseline == 'GR Date'){
        this.due_date = moment(String(this.received_date)).add(this.table.payment_term.due_invoice_period, 'days').format("DD/MM/YYYY");
      }
    }
    // "table.data": function () {
      // console.log(this.table.data);
    // },
    // search: function () {
    //   this.offset = 0;
    // },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

</style>